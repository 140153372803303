.inscriptionDemandeEnvoyeePageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  width: 100vw;

  box-sizing: border-box;
  .inscriptionDemandeEnvoyeePage {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;

    width: 100%;
    max-width: 1300px;
    padding: 0px 64px 0px 64px;
    box-sizing: border-box;

    .inscriptionDemandeEnvoyeePage__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 64px;
      width: 100%;
      .inscriptionDemandeEnvoyeePage__header__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
      .inscriptionDemandeEnvoyeePage__header__text {
        align-self: flex-start;
        p {
          margin: 0;
        }
      }
    }

    .inscriptionDemandeEnvoyeePage__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      width: 100%;
      & > article {
        // min-width: 1200px;
        // width: 100%;
      }
    }
    .inscriptionDemandeEnvoyeePage__footer {
      width: 100%;
    }
  }
}
