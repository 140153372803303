.research_loader_container {
    height: 100vh;
    margin: 64px;

    button {
        all: unset;
        width: 121px;
        height: 42px;
        background: #DFDFDF;
        border: 1px solid #58585A;
        color: #58585A;
        border-radius: 20px 6px 6px 20px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 8px;
    
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;
        width: 100%;
        margin-top: 32px;
        h1 {
            text-align: center;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 64px;
            text-align: center;
            width: fit-content;
            white-space:break-spaces;
        }

        p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            display: flex;
            color: #003E5C;
        }

        .loader {
            height: 12px;
            width: 85%;
            border-radius: 12px;
            -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            -ms-border-radius: 12px;
            -o-border-radius: 12px;

            div {
                border-radius: 12px;
                color: "#003E5C";
            }

        }
    }
    
}