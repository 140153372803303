.errorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 32px;
  width: 100vw;
  min-height: 100vh;

  box-sizing: border-box;
  header{
    width: 50%;
    text-align: center;
    h1{
      height: max-content;
      overflow-x: hidden;
  }
  }
  main{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width:100%;
  }
}
