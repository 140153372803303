.etablissementList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  height: 100vh;
  overflow-y: scroll;
  flex-grow: 1;
  // background-color: black;
  .etablissementList__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #003e5c;
      margin: 0;
    }
  }

  .etablissementList__main{
    display: flex;
    flex-direction: column;
    gap: 32px;
    // background-color: red;
    height: 1000vh;
    // overflow-y: scroll;
    // flex-grow: 1;
  }
}
