.confirmeParticipationPopup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;
  box-sizing: border-box;
  max-width: 629px;
  background: #ffffff;
  border: 1px solid #afafaf;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  .confirmeParticipationPopup__header {
    display: flex;
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #003e5c;
    }
  }
  .confirmeParticipationPopup__main{
   .confirmeParticipationPopup__main__participation.confirmeParticipationPopup__main__participation{
    display: flex;
    flex-direction: column;
   }
  }
  .confirmeParticipationPopup__footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
