.inscriptionDroitImage {
  display: flex;
  flex-direction: column;

  padding: 20px;
  gap: 32px;
  width: 866px;
  border-radius: 20px;

  box-sizing: border-box;
  background: #f6f6f6;

  .inscriptionDroitImage__main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .inscriptionDroitImage__main__text {
      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #003e5c;
        margin: 0;
      }
    }
  }
  .inscriptionDroitImage__main__inputs {
    display: flex;
    gap: 32px;
  }
  .inscriptionDroitImage__main__errorLabel {
    height: 1px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #e13b17;
  }
}
