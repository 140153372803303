.mapsSearchSection {
  .mapsSearchSection__search {
    position: relative;


    & >.icon_wrapper {
      position: absolute;
      top: 25%;
      left: 10px;
    }

    .mapsSearchSection__search__input {
      width: 298px;
      height: 42px;
      padding: 16px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 6px;
      outline: none;
      border: none;
      // margin-top: 8px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      box-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.2);
      color: #809eae;
      padding-left: 40px;

      font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
color: #003E5C;


    }
    .mapsSearchSection__search__input[has-error="yes"] {
      border: 1px solid #e13b17;
    }

    .mapsSearchSection__search__options {
      position: absolute;
      top: calc(
        100% + 4px
      ); /* adjust this value as needed to position the dropdown */
      left: 0;
      min-width: 289px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-top: none;
      // height: 250px;
      overflow-y: scroll;
      // gap: 16px;
      box-sizing: border-box;
      // // height: 556px;

      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      z-index: 100;
      option {
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #003e5c;
        margin-bottom: 16px;
        white-space: normal;
        word-wrap: break-word;
        cursor: pointer;

        &:hover {
          background-color: darken(#ffffff, 10%);
        }
      }
    }
  }

  .mapsSearchSection__errorLabel {

      height: 1px;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #e13b17;
  }
}
