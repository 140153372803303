.etablissementSearchPage {
  width: 100vw;
  padding: 24px 64px;
  box-sizing: border-box;
  overflow: hidden;
  height: 150h;
  // background-color: black;
  
  .etablissementSearchPage__header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    &>h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #003e5c;
    }
  }
 .etablissementSearchPage__main{
    display: flex;
    flex-direction: column;
    gap: 128px;
    overflow: hidden;
 }
}
