.inscriptionBackground{
    position: relative;
   width: 100vw;
    .inscriptionBackground__picture{
        width: 100%;
        height: 513px;
        margin-top: auto;
        background-size: cover;
        object-fit: fill;  

        display: flex;
        align-items: center;
        justify-content: center;
        
        .titles_container {
            align-self: flex-start;
            margin: 64px 0 32px;
            display: flex;
            flex-direction: column;
            gap: 84px;
            h1 {
                font-family: 'Century Gothic', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 48px;
                line-height: 59px;
                /* identical to box height */

                text-align: center;
            }

            h2 {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
            }
        }
    }


}