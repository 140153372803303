.scroll_button {
    all: unset;
    justify-content: center;
    position: fixed;
    z-index: 999;
    right: 9%;
    bottom: 7%;
    align-items: center;
    background: linear-gradient(246.3deg, #003E5C 14.46%, #00AEC3 84.5%);
    box-shadow: 0px 1px 14px rgb(0 0 0 / 20%);
    border-radius: 50%;
    height: 80px;
    width: 80px;
    color: #FFFFFF;
    // padding: 16px 32px;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    &:hover {
        background: linear-gradient(246.3deg, #00608f 14.46%, #00dcf6 84.5%);
    }
}