.inscriptionRecapParticipation {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 32px;

  // max-width: 1122px;
  background: #f6f6f6;
  border: 1px solid #003e5c;
  border-radius: 8px;
  &[is-recap-page="no"] {
    border: none;
  }
  
width: 100%;
  .inscriptionRecapParticipation__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .inscriptionRecapParticipation__main {
    display: flex;
    flex-direction: column;
    // width: 923px;
    width: 100%;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
