.etablissementCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;

  max-width: 923px;
  min-height: 242px;

  background: #f6f6f6;
  border-radius: 8px;
  .etablissementCard__logoSection {
    .etablissementCard__logoSection__logo {
      width: 80px;
      height: 80px;
      object-fit: contain;
      background-size: cover;
    }
  }

  .etablissementCard__etablissementInfos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;

    width: 300px;
    min-height: 117px;
    .etablissementCard__etablissementInfos__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      min-height: 45px;
      h1,
      h2 {
        margin: 0;
      }
      h1 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #003e5c;
      }
      h2 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #003e5c;
      }
    }

    .etablissementCard__etablissementInfos__main {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 8px;
      address {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #003e5c;
      }
      a {
        align-self: flex-end;

        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */

        text-decoration-line: underline;

        color: #003e5c;
      }
    }
  }
  .etablissementCard__etablissementDisponibilites {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    min-height: 178px;
    box-sizing: border-box;
    .etablissementCard__etablissementDisponibilites__header {
      & > h1 {
        margin: 0;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #003e5c;
      }
    }
    .etablissementCard__etablissementDisponibilites__main {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px;
      gap: 12px;
      width: 415px;
      min-height: 142px;
      background: #ffffff;
      border-radius: 2px;
      .etablissementCard__etablissementDisponibilites__main__dayNames {
        align-items: center;
        justify-content: space-between;
        width: 100%;
        display: flex;
        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #003e5c;
          width: 75px;
        }
      }
      .etablissementCard__etablissementDisponibilites__premiereSemaine,
      .etablissementCard__etablissementDisponibilites__deuxiemeSemaine, 
      .etablissementCard__etablissementDisponibilites__troiseimeSemaine, 
      .etablissementCard__etablissementDisponibilites__quatriemeSemaine {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px;
          gap: 10px;
          width: 75px;
          height: 37px;
          background: #ffffff;
          border: 1px solid #00aec3;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
          border-radius: 6px;

          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #00aec3;
          &[button-type="selected"] {
            color: #ffffff;
            background: #00aec3;
            border: 1px solid #ffffff;
          }
          &[button-type="disabled"] {
            background-color: #09273683;
            color: #fff;
          }
        }
        .etablissementDisponibilites__main__noDayDisponible {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 75px;
        }
      }
    }
  }
}
