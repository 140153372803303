.calendarDropdownFilter__popup {
    position: absolute;
    top:40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    gap: 32px;
    margin-top: 10px;

    position: absolute;
    width: 479px;
    height: 380px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 26px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);


    .calendarDropdownFilter__popup__header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
      width: 415px;
      height: 20px;

      h1 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #003e5c;
      }
    }
    .calendarDropdownFilter__popup__main {
      display: flex;
      gap: 4px;
      width: 100%;

      .calendarDropdownFilter__popup__main__disponibilitesColumn {
        gap: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: max-content;
      }
    }
    .calendarDropdownFilter__popup__main_2{
      display: flex;
      gap: 4px;
      width: 100%;
      .calendarDropdownFilter__popup__main__disponibilitesColumn_2 {
        gap: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: max-content;
      }
    }
    .calendarDropdownFilter__popup__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 16px;
        gap: 8px;
        width: 97px;
        height: 42px;

        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
        border-radius: 6px;

        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        &[button-type="annuler"] {
          color: #003e5c;
          background: #ffffff;
          border: 1px solid #003e5c;
        }
        &[button-type="valider"] {
          background: #00aec3;
          border: none;
          box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
          border-radius: 6px;
          color: #ffffff;
        }
      }
    }
  }