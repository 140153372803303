.contactSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 24px;

  background: #f6f6f6;

  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  .contactSection__header {
    margin-left: 5px;
    width: 100%;
  }
  .contactSection__main {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;

    // box-sizing: border-box;
    .contactSection__main__infosRef,
    .contactSection__main__infosDE {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      gap: 32px;
      background: #ffffff;
      border-radius: 20px;
      header {
        h3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #003e5c;
        }
      }
      main {
        display: flex;
        box-sizing: border-box;
        // flex-wrap: wrap;
        div {
          article {
            width: 100px !important;

            label {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;

              color: #003e5c;
            }
          }
        }
      }
    }
    .contactSection__main__infosRef {
      max-width: 618px;

    }
    .contactSection__main__infosDE {
      max-width: 500px;
    }
  }
}

@media (max-width: 1024px) {
  .contactSection__main {
    flex-direction: column;
    gap: 64px;
    padding: 24px;
    .contactSection__main__infosRef,
    .contactSection__main__infosDE {
      min-width: 100%;
      border: 1px solid ed;
    }
  }
}
