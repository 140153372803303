.customeModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0; /* Add right: 0 */
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%; /* Change from 100vh to 100% */
  .customeModal__container {
    position: fixed;

    width: 100%;
    height: 100%; /* Change from 100vh to 100% */
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 200;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: ease;
    opacity: 1;
    overflow: hidden;
    z-index: 200;
  }
  .customeModal__content {
    position: absolute;
    z-index: 300;
    right: 5%;
  }
}
