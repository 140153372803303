.inputSection {
  width: 300px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  // min-width: 45%;
  margin-bottom: 32px;
  box-sizing: border-box;
  .inputSection__titleLabel {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 10px;
    min-width: 289px;
    height: 42px;
    margin-top: 8px;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border: 1px solid transparent;

   color : #003E5C;
   font-family:  Montserrat;
   font-size : 14px;
  }
  input[has-error="yes"] {
    border: 1px solid #e13b17;
  }
  .inputSection__errorLabel {
    height: 1px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #e13b17;
  }
}
