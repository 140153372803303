.inscriptionRecap {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 64px;

  // width: 1122px;
  width: 100%;
  background: #f6f6f6;
  border: 1px solid #003e5c;
  border-radius: 8px;
  &[is-recap-page="no"] {
    border: none;
  }
  .inscriptionRecap__InfoPersonnelles {
    display: flex;
    flex-direction: column;
    .inscriptionRecap__InfoPersonnelles__header {
      display: flex;

      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .inscriptionRecap__InfoPersonnelles__main {
      display: flex;
      flex-direction: column;

      .inscriptionRecap__InfoPersonnelles__mainInfos {
        display: flex;
        justify-content: space-between;
        // min-width: 891px;
        & > section {
          width: 50%;
        }
      }
    }
  }

  .inscriptionRecap__droitImage {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .inscriptionRecap__droitImage__main {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .inscriptionRecap__droitImage__main__text {
        p {
          margin: 0;
        }
      }
      .inscriptionRecap__droitImage__main__radioButtons {
        display: flex;
        gap: 32px;
      }
    }
  }
}
