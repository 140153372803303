.breadCrumbNavbar {
  display: flex;
  align-items: center;
  width: 95vw;
  margin-top: 24px;
  .breadCrumbNavbar__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 121px;
    height: 42px;
    background: #dfdfdf;
    border: 1px solid #58585a;
    border-radius: 20px 6px 6px 20px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #58585a;

    cursor: pointer;
  }
  .breadCrumbNavbar__breadcrumbs {
    position: absolute;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    z-index: -10;
  }
}
