.paginationFooter{
    display: flex;
    flex-direction: row;
    margin-top: 40px;
}

.paginationFooter__pageLimitSelectContainer{
    display: flex;
    margin-left: 650px;
    justify-content: space-between;
    
}

.pageSizeSelect{
    display: flex;
    border: none;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 10px;
    background: #f6f6f6;
    color: #003e5c;
    border-radius: 5px;
}

.par_page{
    color: #003e5c;
}