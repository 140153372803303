.dropdown {
  

  div {

    position: relative;

    div.icon_wrapper {
      position: absolute;
      top: 25%;
      left: 16px;
    }

    select{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 16px;
      gap: 10px;
      padding-left: 46px;
      padding-right: 30px;
      width: 298px;
      height: 42px;
      border: unset;
      /* White */

      background: #ffffff;
      border-radius: 6px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url('../../../assets/svgs/down_arrow.svg');
      background-repeat: no-repeat;
      background-position-x: calc(100% - 11px);
      background-position-y: 50%;
      background-color: #ffffff;

      text-overflow: ellipsis;
      white-space: nowrap;

      &:focus-visible  {
        outline-offset: unset;
        outline: unset;
        border: unset;
      }
  }
  
}
}
