.vmvDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: fit-content;
  // height: 304px;
  justify-content: center;
  padding: 64px;
  margin: 0 auto;
  box-sizing: border-box;
  background: rgba(178, 229, 237, 0.2);
  width: 100vw;
  .vmvDescription__container {
    strong {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      align-self: flex-start;
      width: 100%;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      align-self: flex-start;
      margin: 0;
      width: 100%;
    }
  }
}
