.headerTopBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100vw;
  max-height: 100px;
  padding: 10px 40px 10px 40px;
  box-sizing: border-box;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
  .headerTopbar__left {
    h1 {
      margin: 0;
      cursor: pointer;
    }
  }
  .headerTopbar__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: 32px;
    // width: 447px;
    .headerTopbar__right__faqButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 67px;
      height: 42px;

      background: #ffffff;
      border: 1px solid #003e5c;
      color: #003e5c;
      box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
      border-radius: 6px;

      text-decoration: none;
    }

   
    .headerTopbar__right__deconnexion {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 139px;
      height: 42px;

      text-decoration: none;
      a {
        color: #00aec3;
      }
    }
    // }
  }
}
