.inscriptionInfoPersonnelles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 20px;
  gap: 64px;

  width: 866px;
  box-sizing: border-box;
  background: #f6f6f6;
  border-radius: 8px;
  border-radius: 20px;
  
  .inscriptionInfoPersonnelles__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    label{
      text-indent: 8px;
    }
    .inscriptionInfoPersonnelles__main__civiliteContainer{
      margin-bottom: 32px;
      label{
        text-indent: 8px;
      }
    }
    .inscriptionInfoPersonnelles__main__restOfInfosContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      label{
        text-indent: 8px;
      }
    }
  }
}

.inscriptionInfoPersonnelles__right{
  margin-bottom: 30px;
  select{
    
    option{
    
    }
  }
}
