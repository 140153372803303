.etablissementSearchBar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 64px;
  gap: 16px;
  .etablissementSearchBar__inputs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 24px 64px;
    gap: 32px;
    min-height: 90px;
    

    background: #f6f6f6;
    box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    box-sizing: border-box;
    .etablissementSearchBar__inputs__container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      padding: 0px;
      gap: 16px;
      // width: 926px;
      min-height: 42px;
    }

    button[type="submit"] {
      padding: 0px 16px;
      gap: 8px;
      width: 157px;
      height: 42px;
      background: #00aec3;
      box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      display: flex;
      align-items: center;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;

    }
  }

  .etablissementSearchBar__filters {
    p {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #003E5C;
    }
  }
}
