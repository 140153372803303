.vmv_research_container {

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;

    div {
        background: radial-gradient(185.66% 652.99% at 55.8% 88.71%, #B2E5ED 0%, #FFFFFF 100%);
        padding: 64px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;

        p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #003E5C;
            white-space: pre-wrap;
        }
    }
}

