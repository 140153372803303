$linkButton_color1_gradient: #003e5c;
$linkButton_color2_gradient: #00aec3;


.linkButton {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  // margin-top: 42px;
  padding: 16px 32px;
  gap: 8px;

  background: linear-gradient(
    246.3deg,
    $linkButton_color1_gradient 14.46%,
    $linkButton_color2_gradient 84.5%
  );
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;

  
  &:hover {
    background: linear-gradient(
      246.3deg,
      lighten($linkButton_color1_gradient, 10%) 14.46%,
      lighten($linkButton_color2_gradient, 10%) 84.5%
    );
  }
}
