.cloture_container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    .cloture_header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       padding: 46px 32px 0px;

        gap: 32px;

        h1 {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 59px;
            /* identical to box height */

            text-align: center;
        }

        h2 {
            /* TItle 1 */
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
        }

        .gradient_div {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 64px;
            gap: 32px;
            background: radial-gradient(168.79% 440.33% at 50% 50%, #B2E5ED 0%, rgba(178, 229, 237, 0) 100%);
            border-radius: 20px;

            p{
                font-family: 'Montserrat', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #003E5C;

                span {
                    font-weight: 700;
                }
            }
        }

    }
}