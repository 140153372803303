.updateCollaborateurPopup {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 40px;
  position: relative;
  width: 1011px;
  background: #ffffff;
  z-index: 1000;
  .updateCollaborateurPopup__header {
    display: flex;
    align-items: center;
    width: 100%;
    h1 {
      margin-left: auto;
      margin-right: auto;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #00aec3;
    }
    .updateCollaborateurPopup__header__closeIconButton {
      border: none;
      background: none;

    }
  }
  .updateCollaborateurPopup__form {
    width: 100%;

    .updateCollaborateurPopup__form__main {
      width: 100%;

      .updateCollaborateurPopup__form__main__informationsPersonnelles {
        display: flex;
        flex-direction: column;
        width: 100%;

        .updateCollaborateurPopup__form__main__informationsPersonnelles__civiliteContainer {
        }
        .updateCollaborateurPopup__form__main__informationsPersonnelles__restOfInfosContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
      }
      .updateCollaborateurPopup__form__main__droitImage{
        &>article{
            background-color: transparent;
            width: 100%;
        }
      }
    }
    .updateCollaborateurPopup__form__footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
  }
  border-radius: 26px;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  -ms-border-radius: 26px;
  -o-border-radius: 26px;
}
