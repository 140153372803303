.homePageModification {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 100vw;
  box-sizing: border-box;
  .homePageModification__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding: 64px;
    box-sizing: border-box;

    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 59px;
      text-align: center;
      color: #003e5c;
    }
    h2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #003e5c;
    }
  }
  .homePageModification__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 48px;

    width: 100%;
    padding: 0px 24px 0px 24px;
    box-sizing: border-box;
    .homePageModification__main__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      padding: 0px 64px 0px 64px;
      box-sizing: border-box;
      justify-content: space-around;
    }
    
    .homePageModification__main__infos {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      padding: 0px 64px 0px 64px;
      justify-content: center;

      width: 100%;
      box-sizing: border-box;
      .homePageModification__main__infos__contactInfos {
        display: flex;
        width: 30%;

        & > article {
          & > main {
            & > section {
              min-width: 100%;
              & > main {
                flex-wrap: wrap;
              }
            }
          }
        }
      }
      .homePageModification__main__infos__collabInfos {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 70%;

        gap: 24px;
        & > article {
          // max-width: 1027px;
          border: none;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .homePageModification__main__infos {
    flex-wrap: wrap;
    .homePageModification__main__infos__contactInfos {
      display: flex;
      // width: 35%;
      min-width: 100%;
    }
    .homePageModification__main__infos__collabInfos {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      min-width: 100%;
      gap: 24px;
      & > article {
        border: none;
      }
    }
  }
}
