.dropdownSection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  .dropdownSection__titleLabel {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  div.icon_wrapper {
    position: absolute;
    top: 30%;
    left: 35px;
  }

  select {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px 0px 16px;
    gap: 10px;
    width: 329px;
    height: 42px;
    margin-top: 8px;
    background-color: #ffffff;

    box-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border: none;

    // Option text styles
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #003e5c;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url('../../../assets/svgs/down_arrow.svg');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 6px);
    background-position-y: 50%;
    background-color: #ffffff;

    &:focus-visible  {
      outline-offset: unset;
      outline: unset;
      border: unset;
    }
  }
  select[has-error="yes"] {
    border: 1px solid #e13b17 !important;
  }

  .dropdownSection__errorLabel {
    height: 1px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #e13b17;
  }
}
