.faqPage {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  gap: 64px;
  .faqPage__headers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .faqPage__questions {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: auto;
    padding: 0px 64px;

    .faqPage__questions__accordion {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
    }

    /** The restyling rules of the accordion's items are inside the main 'index.css' 
     * file in the root folder inside the classes :
     * '.mantine-Accordion-control'
     * '.mantine-Accordion-label'
     * '.mantine-Accordion-content'
    */
  }
  .faqPage__returnButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    padding: 16px 32px;
    gap: 8px;
    width: 291px;
    height: 61px;

    background: linear-gradient(246.3deg, #003e5c 14.46%, #00aec3 84.5%);
    box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
    border-radius: 40px;

    // Button text
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;

    text-decoration: none;
  }

  footer{
    width: 100vw;
    height: 100px;
    margin-top: auto;
    background-size: cover;
    object-fit: fill;
  }
}
