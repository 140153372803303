.gradient_button {
    all: unset;
    background: linear-gradient(246.3deg, #003E5C 14.46%, #00AEC3 84.5%);
    box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    color: #FFFFFF;
    padding: 16px 32px;
    cursor: pointer;

    &:hover {
        background: linear-gradient(246.3deg, #00608f 14.46%, #00dcf6 84.5%)
    }
}