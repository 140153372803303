.metier_container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    gap: 128px;
    // margin: 128px 0;
    position: relative;
    //flex-shrink: 0;
    .bg {
        position: absolute;
        top: 20px;
        left: 0;
        z-index: -1;
        width: 100vw;
        //height: 100%;
        height: 3087px;
        object-fit: cover;
    }

    h1 {
        margin-top: 128px;
        text-align: center;
        justify-self: center;
        align-self: center;
    }
    section {
        display: flex;
        // flex-wrap: wrap;
        align-self: flex-start;
        //position: absolute;
        
        &:nth-of-type(even) {
            align-self: flex-end;
            flex-direction: row-reverse;
            transform: translateX(-3%);
            -webkit-transform: translateX(-3%);
            -moz-transform: translateX(-3%);
            -ms-transform: translateX(-3%);
            -o-transform: translateX(-3%);
        }   

        &:nth-of-type(2),
        &:nth-of-type(5) {
                background-color: #FFF;
        }
        &:last-of-type {
            margin-bottom: 128px;
        }

        

        div {
            // width: 393px;
            // height: 420px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 60px 60px 0 ;
            gap: 32px;

            .headers_container {
                all: unset;
                display: flex;
                flex-direction: column;
                gap: 16px;


                h2 {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    color: #003E5C
                }

                h3 {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #003E5C;
                }

            }
           
            
            .seprator{
                width: 70px;
                height: 7px;
                background: #003E5C;
                padding: 0;
            }
            
            p {
                padding: 20px 0;
            }
        }

        img {
            width: 464px;
            height: 420px;
        }
        transform: translateX(6%);
        -webkit-transform: translateX(6%);
        -moz-transform: translateX(6%);
        -ms-transform: translateX(6%);
        -o-transform: translateX(6%);
}


}



@media screen and (max-width: 1024px) {
    .metier_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin: 0 64px;

        section {
            transform: translateX(0%) !important;
            -webkit-transform: translateX(0%) !important;
            -moz-transform: translateX(0%) !important;
            -ms-transform: translateX(0%) !important;
            -o-transform: translateX(0%) !important;
            align-self: center;
}
    }
}