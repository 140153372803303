.rechercheBarStart {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;
  gap: 32px;

  // width: 966px;
  background: #f6f6f6;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  .rechercheBarStart__form {
    .rechercheBarStart__form__main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      & > div {
        height: 70px;

        .rechercheBarStart__form__main__titleLabel {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #003e5c;
          margin-bottom: 8px;
        }
      }
    }
    .rechercheBarStart__form__footer {
      width: 100%;
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
