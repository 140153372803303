.disponibiliteButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 75px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #00aec3;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #00aec3;

  &[is-selected="selected"] {
    background: #00aec3;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
