.inscriptionRecapPageContainer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  box-sizing: border-box;

  .inscriptionRecapPage {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 64px;
    width: 100%;
    box-sizing: border-box;
    padding: 24px 64px;
    max-width: 1250px;

    .inscriptionRecapPage__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 64px;
      // width: 1122px;
      width: 100%;
      box-sizing: border-box;
      h1 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #003e5c;
      }
      .inscriptionRecapPage__header__text {
        align-self: flex-start;
        p {
          margin: 0;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #003e5c;
        }
      }
    }
    .inscriptionRecapPage__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 64px;
      width: 100%;
      & > button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        gap: 8px;
        width: 329px;
        height: 54px;
        background: linear-gradient(246.3deg, #003e5c 14.46%, #00aec3 84.5%);
        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
        border-radius: 40px;

        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}
