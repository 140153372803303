.phoneSection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  // min-width: 45%;
  margin-bottom: 32px;
  box-sizing: border-box;
  .phoneSection__titleLabel {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .phoneSection__phoneContainer {
    display: flex;
    align-items: center;
    margin-top: 8px;
    box-sizing: border-box;
    .phoneSection__phoneContainer__phoneCode {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      padding: 0px 16px;
      margin-bottom: 1px;
      font-size: 13px;
      border: none;
    }
    input {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: content;
      padding: 0px 16px;
      padding-left: 43px;
      gap: 10px;

      min-width: 229px;

      height: 42px;
      background: #ffffff;

      box-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      border: 1px solid transparent;

      color: #003e5c;
      font-family: Montserrat;
      font-size: 14px;
    }
    input[has-error="yes"] {
      border: 1px solid #e13b17;
    }
  }
  .phoneSection__errorLabel {
    height: 1px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #e13b17;
  }
}
