.calendarDropdownFilter {
  position: relative;

  .calendarDropdownFilter__button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    gap: 10px;
    width: 298px;
    height: 42px;
    background: #ffffff;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    // margin-top: 8px;
    box-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.2);
    & > div {
      margin-top: 3px;
    }

    & > section {
      position: absolute;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      margin-left: 30px;
    //  width: 1000px;
      width: 100%;
      & > p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #003e5c;

      }
    }
  }
}
