.inscriptionPage {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  .inscriptionPage__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1115px;
    gap: 64px;

    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;

      color: #003e5c;
    }
  }
  .inscriptionPage__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;

    button[type="submit"] {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 32px;
      gap: 8px;
      width: 158px;
      height: 54px;
      background: linear-gradient(246.3deg, #003e5c 14.46%, #00aec3 84.5%);
      box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
      border-radius: 40px;
      box-sizing: border-box;

      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
    }
  }
}
